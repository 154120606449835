//
// Section
//

.c-section {
    display: block;
    padding: ($gutter * 1.5) 0;

    @include media-breakpoint-up(md) {
        padding: ($gutter * 2) 0;
    }

    &:nth-child(even) {
        background-color: #f4f5f6;
    }

    &__text {
        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &__image {
        &--left {
            margin-bottom: $gutter;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                padding-right: $gutter;
            }
        }

        &--right {
            margin-top: $gutter;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
                padding-left: $gutter;
            }
        }
    }

    &.is-in-view [class*="col-"] {
        &:first-child {
            @include media-breakpoint-up(md) {
                animation: fadeInLeftSmall $animation-medium ease 0s 1 forwards;
            }
        }

        &:last-child {
            @include media-breakpoint-up(md) {
                animation: fadeInRightSmall $animation-medium ease 0s 1 forwards;
            }
        }
    }

    &.js-on-screen-animation [class*="col-"] {
        &:first-child {
            @include media-breakpoint-up(md) {
                opacity: 0;
            }
        }

        &:last-child {
            @include media-breakpoint-up(md) {
                opacity: 0;
            }
        }
    }
}

@keyframes fadeInLeftSmall {
    0% {
        opacity: 0;
        transform: translate3d(-2%, 0, 0);
    }

    75% {
        transform: none;
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInRightSmall {
    0% {
        opacity: 0;
        transform: translate3d(2%, 0, 0);
    }

    75% {
        transform: none;
    }

    100% {
        opacity: 1;
        transform: none;
    }
}
