//
// Text input
//

.c-textinput {
    @include shadow(1);

    width: 100%;
    height: auto;
    padding: ($gutter / 4) ($gutter / 2);

    color: $gray;
    font-size: 1.3rem;
    font-weight: $font-weight-light;

    border-radius: 0;
    border: 1px solid $gray-lighter;
    background-color: #fff;

    transition: background $animation-xshort ease-in-out, border-color $animation-xshort ease-in-out;

    &:hover,
    &:focus {
       background: #fafafa;
    }

    &:focus {
        border: 1px solid $gray;
    }

    &--small {
        height: 41px;
    }

    &--disabled {
        background-color: $gray-lightest;
    }

    &.has-error,
    &--invalid {
        border: 1px solid $red;
    }

}
