//
// Header
//

$headerHeight: 140px;

.o-header {
    @include clearfix();
    @include shadow(2);

    display: block;
    padding: ($gutter /2) 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
        padding: 0;
    }

    &,
    > .container {
        position: relative;

        @include media-breakpoint-up(lg) {
            height: $headerHeight;
        }
    }

    &__brand,
    &__contact {
        @include media-breakpoint-up(lg) {
            @include valign();
        }
    }

    &__brand {
        @include clearfix();

        position: relative;

        display: block;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            float: left;
        }
    }

    &__logo {
        display: block;
        width: 300px;
        max-width: 55%;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            max-width: 50%;

            padding: 0 $gutter 0 0;
            margin: 0 $gutter 0 0;

            border-right: 1px solid $gray-lighter;
        }

        h1 {
            font-size: 0;
            visibility: hidden;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__tagline {
        display: none;
        height: $font-size-h3;
        margin: 0;

        color: $gray-light;
        font-size: $font-size-h4;
        font-style: italic;

        @include media-breakpoint-up(lg) {
            display: inline-block;
        }
    }

    &__contact {
        display: block;
        margin: 0 auto;

        text-align: center;


        &:hover {
            .fa {
                color: $blue;
            }

            .num {
                color: $red-darker;
            }
        }

        @include media-breakpoint-up(lg) {
            display: inline-block;
            float: right;
            margin: 0;

            text-align: left;
        }

        .fa {
            display: inline-block;

            right: ($grid-gutter-width / 2);

            @include media-breakpoint-up(lg) {
                position: static;

                display: inline-block;
                padding-right: ($gutter-fixed / 2);

                font-size: 4.35rem;
            }
        }
    }

    &__phone {
        display: none;
        margin: ($gutter / 2) 0 0 0;
        vertical-align: top;

        text-align: center;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            margin: 0;
        }

        .text,
        .num {
            line-height: 1;
        }

        .text {
            display: none;
            margin-bottom: 10px;
            color: $gray-light;
            font-size: 1.2rem;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .num {
            color: $red;
            font-size: 1.8rem;
            font-weight: $font-weight-normal;
        }
    }

    &__burger,
    &__contact .fa {
        transition: color $animation-xshort ease-in-out;

        @include media-breakpoint-down(md) {
            position: absolute;
            top: 50%;
            top: calc(50% - (3rem / 2));

            display: block;

            color: $blue;
            font-size: 3rem;
            line-height: 1;
        }
    }

    &__burger {
        left: ($grid-gutter-width / 2);
        z-index: $zIndex-12;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &:before {
            content: '\f004';

            font-family: 'Platinumsprinkler';
        }

        &:hover,
        &:active,
        &:focus {
            color: $blue;
        }

        &.is-active:before {
            content: '\f005';
        }
    }
}
