// @app // --------------------------------------------------
// @author Piccirilli Dorsey (http://picdorsey.com)
// @copyright 2016

// Configuration
// --------------------------------------------------

@import 'config';

// Mixins
// --------------------------------------------------
@import '_mixins/_aspect-ratio.scss';
@import '_mixins/_bem.scss';
@import '_mixins/_breakpoints.scss';
@import '_mixins/_clearfix.scss';
@import '_mixins/_grid_framework.scss';
@import '_mixins/_grid.scss';
@import '_mixins/_reset.scss';
@import '_mixins/_shadow.scss';
@import '_mixins/_valign.scss';

// Core
// --------------------------------------------------

@import '_core/reboot';
@import '_core/typography';
@import '_core/grid';
@import '_core/form';
@import '_core/functions';
@import '_core/print';
@import '_core/utilities';
@import '_core/debug';

// Vendor
// --------------------------------------------------

@import '_vendor/tether/drop-theme-arrows';

// Components (c-)
// --------------------------------------------------
// Signify that something is a Component. This is a concrete, implementation-specific piece
// of UI. All of the changes you make to its styles should be detectable in the context
// you’re currently looking at. Modifying these styles should be safe and have no side effects.
@import 'components/_alert.scss';
@import 'components/_box-group.scss';
@import 'components/_breadcrumb.scss';
@import 'components/_burger.scss';
@import 'components/_button-dropdown.scss';
@import 'components/_button.scss';
@import 'components/_card.scss';
@import 'components/_checkbox.scss';
@import 'components/_embed.scss';
@import 'components/_form.scss';
@import 'components/_image.scss';
@import 'components/_label.scss';
@import 'components/_modal.scss';
@import 'components/_section.scss';
@import 'components/_table.scss';
@import 'components/_textarea.scss';
@import 'components/_textinput.scss';
@import 'components/_tiles.scss';

// Objects (o-)
// --------------------------------------------------
// Signify that something is an Object, and that it may be used in any number
// of unrelated contexts to the one you can currently see it in. Making modifications
// to these types of class could potentially have knock-on effects in a lot of other
// unrelated places. Tread carefully.
@import 'objects/_app.scss';
@import 'objects/_footer.scss';
@import 'objects/_header.scss';
@import 'objects/_main.scss';
@import 'objects/_navigation.scss';
@import 'objects/_stage.scss';

// Themes (t-)
// --------------------------------------------------
// Signify that a class is responsible for adding a
// Theme to a view. It lets us know that UI Components’ current
// cosmetic appearance may be due to the presence of a theme.
/* No files to import found in themes//* */
// Pages (p-)
// --------------------------------------------------
@import 'pages/landing.scss';
