//
// Navigation
//

.o-navigation {
    position: fixed;
    top: 0;
    left: 0;

    display: block;
    width: 200px;
    height: 100%;
    height: 100vh;
    z-index: $zIndex-10;

    background-color: $gray-darker;

    transform: translateX(-200px);

    @include media-breakpoint-up(lg) {
        position: static;

        display: block;
        width: 100%;
        height: 56px;
        overflow: hidden;

        transform: initial;
    }

    &.is-sticky {
        @include media-breakpoint-up(lg) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;

            display: block;
            width: 100%;
            max-width: 1450px;
            margin: 0 auto;
            height: 56px;
            z-index: $zIndex-12;

            // transform: initial;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0);
        }
    }

    &.is-active {
        @include media-breakpoint-down(lg) {
            @include shadow(5);
        }
    }

    &__nav {
        margin: 0 (-($grid-gutter-width / 2));

        font-size: 0;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }
    }

    &__item {
        display: block;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            width: auto;
        }

        a:hover,
        &.is-active a {
            background-color: $red;
        }

        a {
            display: block;
            width: 100%;
            padding: ($gutter-fixed / 2) ($gutter-fixed / 1.5);
            color: #fff;
            font-weight: $font-weight-bold;
            font-size: 1.1rem;

            transition: background-color $animation-xshort ease-in-out;

            @include media-breakpoint-up(lg) {
                width: auto;
            }

            @include media-breakpoint-up(xl) {
                padding: ($gutter-fixed / 2) $gutter-fixed;
                font-size: 1.2rem;
            }
        }
    }
}
