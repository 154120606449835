//
// Footer
//

.o-footer {
    @include clearfix();

    display: block;
    padding: ($gutter * 1.5) 0;

    background-color: #222222;

    &__logo {
        display: block;
        width: 100%;
        max-width: 250px;
        height: auto;
        margin: 0 auto ($gutter * 2) auto;
        padding: 0 $gutter;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: 250px;
            max-width: initial;
            padding: 0 ($gutter * 1.5) 0 0;
            margin: 0 ($gutter * 1.5) ($gutter * 2) 0;
            float: left;

            border-right: 1px solid #484848;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 ($gutter * 1.5) 0 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__contact {
        display: inline-block;
        float: left;
    }

    &__address {
        display: block;
        margin: 0 0 $gutter 0;

        color: #fff;
        line-height: 1.2;

        @include media-breakpoint-up(md) {
            margin: 0 0 5px 0;
        }
    }

    &__num {
        display: block;
        color: $blue;
    }

    &__misc {
        display: block;
        clear: both;

        @include media-breakpoint-up(lg) {
            float: right;
            clear: initial;

            text-align: right;
        }
    }

    &__license {
        display: block;
        margin: 0 0 7px 0;

        color: #fff;
        font-size: 1.6rem;

        span {
            color: $red;
        }
    }

    &__facebook {
        display: block;
        margin: 0 0 10px 0;
        font-size: 1.2rem;
        font-size: 0;

        color: #fff;

        .fa {
            color: $blue;
            font-size: 110%;

            transition: color $animation-xshort ease-in-out;
        }

        &:hover {
            color: $gray-lightest;

            cursor: pointer;

            .fa {
                color: darken($blue, 10%);
            }
        }
    }

    &__copyright {
        display: block;
        margin: 0;

        color: #666666;
        font-size: 1.15rem;
        line-height: 1;
    }
}
