//
// Form
//

.c-form {
    position: relative;

    display: block;
    padding: $gutter;
    height: 423px;
    width: 100%;

    background-color: $blue;

    transition: height $animation-short ease-in-out;

    @include media-breakpoint-up(md) {
        height: 443px;
    }

    @include media-breakpoint-up(xl) {
        height: 449px;
    }

    &.is-submitting {
        .cs-loader {
            display: block;

            animation: fadeIn $animation-xshort ease 0s 1 forwards;
        }
    }

    &.is-submitted {
        height: 186px;

        @include media-breakpoint-up(md) {
            height: 257px;
        }

        @include media-breakpoint-up(xl) {
            height: 232px;
        }

        .c-form__fields {
            animation: fadeOut $animation-short ease 0s 1 forwards;
        }

        .c-form__text {
            animation: fadeIn $animation-short ease $animation-xshort 1 forwards;
        }

        .c-form__headline {
            .before {
                display: none;
            }

            .after {
                display: block;
            }
        }
    }

    &__headline {
        .p-landing & {
            display: block;
            margin: 0 0 ($gutter / 1.5) 0;

            color: #fff;
            font-size: 1.8rem;
            font-weight: $font-weight-bold;
            letter-spacing: -1px;
            text-align: center;

            @include media-breakpoint-only(lg) {
                font-size: 1.4rem;
            }

            .before {
                display: block;
            }

            .after {
                display: none;
            }
        }
    }

    &__field {
        opacity: 1;
    }

    &__group {
        display: block;
        margin: 0 0 ($gutter / 1.5) 0;

        &:last-child {
            margin: 0;
        }
    }

    &__text {
        .p-landing & {
            position: absolute;
            top: 78px;

            padding-right: ($gutter / 2);

            color: #fff;
            margin: 0;

            opacity: 0;

            pointer-events: none;
        }
    }

    .c-textinput {
        &.disabled {
            color: #c1c7ca;

            background: #eee;

            pointer-evets: none;
        }
    }

    .c-button {
        display: block;
        margin: $gutter auto 0 auto;

        &.disabled {
            background: #fff;
            border-color: #eaeced;
            color: #c1c7ca;

            pointer-events: none;
        }
    }
}

.cs-loader {
    position: absolute;
    top: 0;
    left: 0;

    display: none;
    height: 100%;
    width: 100%;
    background: rgba(red($blue), green($blue), blue($blue), 0.8);
    opacity: 0;

    pointer-events: none;

    &:before {
        content: 'Sending';

        position: absolute;
        top: 30%;
        right: 0;
        left: 0;

        display: block;
        margin: 0 auto;

        color: #fff;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        text-align: center;
    }
}

.cs-loader-inner {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    width: 100%;
    color: #fff;
    padding: 0 100px;
    text-align: center;
}

.cs-loader-inner label {
    font-size: 2rem;
    opacity: 0;
    display: inline-block;
}

@keyframes lol {

    0% {
        opacity: 0;
        transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        transform: translateX(0px);
    }
    66% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}
@-webkit-keyframes lol {

    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }
    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.cs-loader-inner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 3s 500ms infinite ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
