//
// Landing
//

.p-landing {

    &__overview {
        .c-section__text {
            margin: 0 0 $gutter 0;

            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
    }

    &__clients {
        position: relative;

        text-align: center;

        // @include media-breakpoint-down(md) {
        //     padding-top: 100px;
        //     padding-bottom: 0;
        //     margin-bottom: -15px;
        // }

        @include media-breakpoint-up(md) {
            padding-top: ($gutter / 1.5);
            padding-bottom: ($gutter / 1.5);
        }

        .container {
            padding-top: 30px;
            margin-bottom: -90px;

            @include media-breakpoint-up(md) {
                padding-top: 60px;
                margin-bottom: -60px;
            }


            @include media-breakpoint-up(lg) {
                padding-top: 0;
                margin-bottom: 0;
                transform: translateY(50%)
            }
        }

        &__client {
            @include valign();

            display: inline-block;
            padding: ($gutter / 1.5);
            width: 50%;
            float: left;

            &:nth-child(2n + 1) {
                @include media-breakpoint-down(sm) {
                    clear: both;
                }
            }

            @include media-breakpoint-up(md) {
                width: 20%;
                float: left;
            }

            @include media-breakpoint-up(lg) {
                padding: ($gutter * 1.5);
            }
        }
    }

    &__engineering {
        text-align: center;
        padding: ($gutter * 2) 0 (($gutter * 2) - $gutter / 2) 0;

        h2 {
            border-bottom: 0;
            padding-bottom: 0;
        }

        p {
            margin: 0 auto ($gutter / 1.5) auto;

            @include media-breakpoint-up(lg) {
                display: block;
                width: 75%;
            }
        }
    }
}
