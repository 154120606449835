//
// Main
//

.o-main {
    h2 {
        display: block;
        padding-bottom: ($gutter / 1.5);
        margin-bottom: ($gutter / 1.5);

        color: $blue;
        font-size: 2.5rem;
        line-height: 1;

        border-bottom: 1px solid $gray-lighter;
    }

    h3 {
        display: block;
        margin-bottom: ($gutter / 4);

        color: $red;
        font-size: 1.5rem;
        line-height: 1;
    }

    p {
        display: block;
        margin-bottom: ($gutter / 1.5);

        color: $gray-light;

        + h2,
        + h3,
        + h4,
        + h5 {
            margin-top: $gutter;
        }

    }

    p,
    li {
        font-size: 1.3rem;
    }

    ul {

    }

    li {
        display: block;
        margin-bottom: ($gutter / 3);

        color: $gray-light;
        font-weight: $font-weight-bold;

        &:before {
            content: '\f002';

            display: inline-block;
            margin-right: 10px;

            color: $red;
            font-family: 'Platinumsprinkler';
            font-size: 110%;
        }
    }
}
