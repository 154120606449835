//
// Button
//

// Config
$button-default: $gray-lightest;
$button-primary: $ui-primary;
$button-secondary: $ui-secondary;
$button-success: $ui-success;
$button-info: $ui-info;
$button-warning: $ui-warning;
$button-danger: $ui-danger;

// Mixin
@mixin button-color-variation ($color) {
    color: text-color($color);
    background-color: $color;
    border-bottom: 2px solid darken($color, 7%);

    &:hover {
        border-color: $color;
        color: darken($color, 15%);
    }

    &:active {
        border-color: $color;
        color: darken($color, 15%);
    }

    &:focus {
        border: 2px solid darken($color, 5%);
    }
}

// Component
.c-button {
    position: relative;
    display: inline-block;
    padding: 0.75rem 2.2rem;

    color: #222;
    font-size: 1.3rem;
    font-weight: $font-weight-medium;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    background-color: $button-default;
    border: 2px solid transparent;
    border-bottom: 2px solid darken($button-default, 7%);
    border-radius: $br-default;

    transition: background-color $animation-xshort linear, color $animation-xshort linear;

    &:hover {
        background: #fff;
        border-color: $button-default;
        color: darken($button-default, 15%);
    }

    &:active {
        background: #fff;
        border-color: $button-default;
        color: darken($button-default, 75%);
        @include shadow(1);
    }

    &:focus {
        outline: 0;
        border: 2px solid darken($button-default, 5%);
        @include shadow(1);
    }

    //
    // Modifiers
    //

    &--default {
        @include button-color-variation ($button-default);
    }

    &--primary {
        @include button-color-variation ($button-primary);
    }

    &--secondary {
        @include button-color-variation ($button-secondary);
    }

    &--success {
        @include button-color-variation ($button-success);
    }

    &--info {
        @include button-color-variation ($button-info);
    }

    &--warning {
        @include button-color-variation ($button-warning);
    }

    &--danger {
        @include button-color-variation ($button-danger);
    }

    // Circle button
    &--circular {
        color: $gray;
        border: 2px solid $gray-light;
        border-bottom-color: $gray-light;
        font-size: 20px;
        padding: 14px 0 15px 0;
        letter-spacing: 0;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 1;
        text-align: center;
        background-color: #fff;

        @include shadow(2);

        //
        // States
        //

        // Icon rotation (+ => x)
        &.is-rotatable {
            .icon {
                transition: transform .5s ease;
            }

            &:hover {
                .icon {
                    transform: rotate(90deg);
                }
            }
        }

        &.is-active {
            .icon {
                transform: rotate(45deg);
            }

            &:hover {
                .icon {
                    transform: rotate(45deg);
                }
            }
        }
    }
}
