//
// Stage
//

.o-stage {
    @include clearfix();

    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    &__image {
        display: block;

    }

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__container {
        position: absolute;
        left: 0;
        right: 0;

        margin: -(518 / 1400)* 100% auto 0 auto;
        padding-top: $gutter;
        width: 100%;

        @include media-breakpoint-up(sm) {
            padding-top: 0;
            margin: -((518 / 1400) / 1.5) * 100% auto 0 auto;
        }

        @include media-breakpoint-up(lg) {
            margin: -((518 / 1400) / 1.4) * 100% auto 0 auto;
        }
    }

    &__copy {
        display: inline-block;
        width: 46%;
        float: right;
        vertical-align: middle;

        color: #fff;
        text-align: right;

        @include media-breakpoint-up(md) {
            animation: fadeInUpSmall $animation-medium ease $animation-xshort 1 forwards;
            opacity: 0;
        }

        .headline {
            margin: 0;
            font-size: $font-size-h3;
            font-weight: 800;
            line-height: 1;

            @include media-breakpoint-up(md) {
                margin: 0 0 ($gutter / 2) 0;
                font-size: 2.8rem;
            }
        }

        .text {
            display: none;
            margin: 0;

            font-size: 1.3rem;
            line-height: 1.15;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }
}

@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}
