//
// App
//

html {
    background: $gray-lighter;

    @include media-breakpoint-down(xs) {
        font-size: 12px;
    }

    @include media-breakpoint-down(md) {
        font-size: 14px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
}

body {
    animation: fadeIn $animation-medium ease 0s 1 forwards;

    &.nav-active {
        width: 100%;
        height: 100vh;

        overflow: hidden;
    }

    &.has-sticky-nav {
        @include media-breakpoint-up(lg) {
            padding-top: 56px;
        }
    }
}

// Object
.o-app {
    @include shadow(2);

    position: relative;

    max-width: 1450px;
    margin: 0 auto;

    background: #fff;

    transform: translateX(0);
    transition: transform $animation-short ease-in-out;

    @include media-breakpoint-up(lg) {
        transform: initial;
    }

    &.nav-active {
        transform: translateX(200px);

        @include media-breakpoint-up(lg) {
            transform: initial;
        }

        &:before {
            display: block;
            animation: fadeIn 250ms ease 0s 1 forwards;
        }
    }

    &.nav-out:before {
        display: block;
        opacity: 1;
        animation: fadeOut 250ms ease 0s 1 forwards;
    }

    &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;

        display: none;
        width: 100%;
        height: 100%;
        z-index: $zIndex-11;

        background-color: rgba(34, 34, 34, 0.6);
        opacity: 0;

        pointer-events: none;
    }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
