//
// Image
//

.c-image {
    position: relative;

    &--left {
        .overlay {
            background-image: url('/assets/images/ui/blue-left-bg.png');
            background-position: left bottom;
            background-repeat: no-repeat;
        }

        figcaption {
            left: 0;

            text-align: left;
        }
    }

    &--right {

        .overlay {
            background-image: url('/assets/images/ui/red-right-bg.png');
            background-position: right bottom;
            background-repeat: no-repeat;
        }

        figcaption {
            right: 0;

            text-align: right;
        }

    }

    img {
        position: relative;
        width: 100%;
        height: auto;

    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: 100%;
    }

    figcaption {
        position: absolute;
        bottom: 0;

        display: block;
        margin: 0;
        padding: ($gutter-fixed / 1.5);
        width: 304px;

        color: #fff;
        font-size: 2.2rem;
        font-weight: $font-weight-light;
        line-height: 1;

        &.is-short {
            padding: 38px (($gutter-fixed / 1.5) - 5);
        }
    }
}
