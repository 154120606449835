//
// Box Group
//


.c-box-group {
    display: block;

    &__box {
        position: relative;

        display: inline-block;
        width: 50%;
        float: left;
        margin-bottom: $grid-gutter-width;
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);

        text-align: center;

        @include media-breakpoint-up(md) {
            width: 33.33%;
        }

        @include media-breakpoint-up(lg) {
            width: 20%;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        .caption {
            position: absolute;
            left: ($grid-gutter-width / 2);
            bottom: 0;

            display: table;
            height: 95px;
            width: calc(100% - #{$grid-gutter-width});
            padding: ($gutter-fixed / 2) ($gutter-fixed / 1.5);

            background-color: rgba(0, 0, 0, 0.8);

            /*
             Target safari
             https://www.lockedowndesign.com/target-safari-7-1-and-8-0-css-styles-osx-yosemite/
            **/
            _::-webkit-full-page-media, _:future, :root & {
                width: 100%;
            }

            span {

                display: table-cell;
                vertical-align: middle;

                color: #fff;
                font-size: 1.3rem;
                text-align: center;
                line-height: 1.2;
            }
        }
    }
}
